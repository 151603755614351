<template>
  <div class="creator-step">
    <div class="creator-step-title">{{ $locale["complete_profile_info_title"] }}</div>
    <div class="creator-step-content">
      <table width="100%">
        <VerifyUserStepsItem :label="$locale.words['user_name']" :value="$user.user" :passed="!!$user.user" />
        <VerifyUserStepsItem :label="$locale.words['full_name']" :value="$user.name" :passed="!!$user.name" />
        <VerifyUserStepsItem :label="$locale.words['location']" :value="$user.country" :passed="!!$user.country" />
        <VerifyUserStepsItem :label="$locale.words['date_of_birth']" :value="date_locale($user.birthday)" :passed="!!$user.birthday" />
      </table>
    </div>
    <div class="creator-step-options">
      <GradientButton v-if="isCompleteProfile" :label="$locale['words']['continue']" to="/user/verify?apply=step2" icon="arrowRight" dir="rtl" />
      <GradientButton
        v-if="!isCompleteProfile"
        :label="$locale['words']['complete_profile']"
        :to="`${$links['my-profile']}&redirect=/user/verify?apply=step1`"
      />
    </div>
  </div>
</template>

<script>
export default {
  components: {
    VerifyUserStepsItem: () => import("./VerifyUserStepsItem.vue"),
  },
  computed: {
    isCompleteProfile: function() {
      return this.$user.user && this.$user.name && this.$user.country && this.$user.birthday;
    },
  },
  mounted: function() {
    if (this.isCompleteProfile) {
      this.$store.commit("setApplyStep", { step: "step1", value: true });
    }
  },
};
</script>
